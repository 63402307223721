<template>
  <v-app id="inspire">
    <overall-header/>


    <v-main class=" lighten-3">
      <v-container>
        <v-row
            align="start"
            justify="space-around"
            style="padding: 5vh"
        >
          <h1 v-if="$route.params.id === undefined">Новый проект</h1>
          <h1 v-else-if="$route.params.id !== undefined">Редактировать проект</h1>
        </v-row>

        <v-row
            justify="space-between"
        >
          <v-col
              cols="12"
              md="6"
          >
            <v-form ref="form">
              <v-text-field
                  v-model="project_title"
                  label="Название проекта"
                  required
                  @input="$v.project_title.$touch()"
                  @blur="$v.project_title.$touch()"
              ></v-text-field>

              <v-checkbox
                  v-model="project_archive"
                  name="archive"
                  label="Архив"
                  @input="$v.project_archive.$touch()"
                  @blur="$v.project_archive.$touch()"
              ></v-checkbox>

              <v-select
                  :items="status_items"
                  item-text="title"
                  item-value="id"
                  v-model="project_status"
                  label="Статус"
                  dense
                  required
                  @input="$v.project_status.$touch()"
                  @blur="$v.project_status.$touch()"
              ></v-select>


              <v-datetime-picker
                  v-model="project_deadline"
                  :time-picker-props="timePickerProps"
              >
                <template slot="dateIcon">
                  <v-icon small>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon small>mdi-alarm</v-icon>
                </template>
              </v-datetime-picker>

              <v-datetime-picker
                  v-model="project_answer_deadline"
                  :time-picker-props="timePickerProps"
              >
                <template slot="dateIcon">
                  <v-icon small>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon small>mdi-alarm</v-icon>
                </template>
              </v-datetime-picker>

              <v-file-input
                  chips
                  multiple
                  show-size
                  counter
                  label="Прикрепите файлы по проекту"
                  @change="fileInputChanged"
                  v-model="current_project_files"
              ></v-file-input>
              <div v-if="project_files.length">
                <h5>Все файлы</h5>
                <v-chip v-for="f in project_files" class="ma-1" v-bind:key="f.name">
                  <v-icon>mdi-download</v-icon>
                  {{ f.name }}
                </v-chip>
              </div>

            </v-form>
          </v-col>

          <v-col
              cols="12"
              md="6"
          >
            <tiptap-vuetify
                v-model="project_description"
                :extensions="editor_extensions"
                placeholder="Описание проекта"
                @input="$v.project_description.$touch()"
                @blur="$v.project_description.$touch()"
            />
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
              cols="12"
              md="12"
          >
            <v-text-field
                v-model="search"
                label="Поиск по секциям"
                flat
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <v-treeview
                selectable
                open-all
                :items="section_items"
                :search="search"
                :filter="filter"
                v-model="project_sections"

                item-text="title"
            ></v-treeview>
          </v-col>
        </v-row>

        <v-btn
            x-large
            color="success"
            dark
            @click="createProject"
            v-if="$route.params.id === undefined"
        >
          Создать проект
        </v-btn>
        <v-btn
            x-large
            color="primary"
            dark
            @click="editProject"
            v-if="$route.params.id !== undefined"
        >
          Обновить
        </v-btn>
      </v-container>
    </v-main>


  </v-app>
</template>

<script>
import OverallHeader from "@/components/OverallHeader";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import {mapActions, mapGetters} from "vuex";
import Axios from '@/helpers/axiosConfig';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

export default {
  name: "CreateProject",
  components: {OverallHeader, TiptapVuetify},
  mixins: [validationMixin],

  validations: {
    project_description: {required},
    project_status: {required},
    project_title: {required},
    project_sections: {required},
    project_answer_deadline: {required},
    project_deadline: {required},
  },

  data: () => ({
    project_deadline: new Date(Date.now()),
    project_deadline_menu: false,

    project_answer_deadline: new Date(Date.now()),
    project_answer_deadline_menu: false,
    project_description: "",
    project_status: '',
    project_title: "",
    project_archive: false,
    project_sections: [],
    current_project_files: [],
    project_files: [],
    uploaded_files: [],
    editor_extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    search: null,
    timePickerProps: {
      format: "24hr",
    }
  }),

  computed: {
    ...mapGetters('statuses', {
      status_items: 'list'
    }),
    ...mapGetters('sectiontypes', {
      section_items: 'list'
    }),
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
  },

  methods: {
    ...mapActions('statuses', {
      fetchStatuses: 'fetchList'
    }),
    ...mapActions('sectiontypes', {
      fetchSectionTypes: 'fetchList'
    }),
    ...mapActions('projects', {
      fetchProject: 'fetchSingle'
    }),
    buildFormData: function() {
      let sections = [];
      this.project_sections.map((s) => {
        sections.push({pk: s})
      })
      let data = {
        "title": this.project_title,
        "archived": this.project_archive,
        "status": this.project_status,
        "deadline": this.project_deadline,
        "answers_deadline": this.project_answer_deadline,
        "description": this.project_description,
        "sections": this.project_sections,
        "files": this.uploaded_files,
      }
      return data
    },

    createProject: function() {
      this.$v.$touch()
      let that = this;
      if (this.$v.$invalid) {
        console.log('ERROR')
      } else {
        console.log('PENDING')

        let formData = new FormData();
        for (let i = 0; i < this.project_files.length; i++) {
          let file = this.project_files[i];
          formData.append("file" + i, file);
        }

        Axios.post('/api_v3/file/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          console.log('fileUpload', response);
          that.uploaded_files = response.data;

          let data = that.buildFormData();
          Axios.post('/api_v3/project/', data).then(function(r) {
            that.$router.push('/project/' + r.data.id)
            console.log(r)
          });
        })


      }
    },

    editProject: function() {
      this.$v.$touch()
      let that = this;
      if (this.$v.$invalid) {
        console.log('ERROR');
      } else {
        console.log('PENDING');

        let formData = new FormData();
        for (let i = 0; i < this.project_files.length; i++) {
          let file = this.project_files[i];
          formData.append("file" + i, file);
        }

        Axios.post('/api_v3/file/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          console.log('fileUpload', response);
          that.uploaded_files = response.data;
          let data = that.buildFormData();
          Axios.put('/api_v3/project/' + that.$route.params.id + '/', data).then(function(response) {
            that.loadDataForProject();
            console.log(response);
          });
        });
      }
    },

    loadDataForProject: function() {
      if (this.$route.params.id) {
        let that = this;
        this.fetchProject({id: this.$route.params.id}).then(function(response) {
          that.project_title = response.data.title;

          that.project_title = response.data.title;
          that.project_archive = response.data.archived;
          that.project_status = response.data.status;
          that.project_deadline = response.data.deadline;
          that.project_answer_deadline = response.data.answers_deadline;
          that.project_description = response.data.description;
          that.project_sections = response.data.sections;
          that.project_files = response.data.files;
        });
      }
    },
    fileInputChanged() {
      console.log(this.project_files)
      this.project_files = [
        ...this.current_project_files,
        ...this.project_files
      ]
    }

  },

  created() {
    this.fetchStatuses();
    this.fetchSectionTypes();
    this.loadDataForProject();
  },

  watch: {
    project_deadline: 'buildFormData',
    project_answer_deadline: 'buildFormData',
    project_description: 'buildFormData',
    project_status: 'buildFormData',
    project_title: 'buildFormData',
    project_archive: 'buildFormData',
    project_sections: 'buildFormData',
  }
}
</script>

<style scoped>

</style>